import httpAdmin from "Config/httpAdmin";
import END_POINT from "Constants/endpoint";
import { convertQueryString, convertQueryStringAdmin } from "Utils/string";

function getListCourseAdmin(params) {
  const url = convertQueryString({page:  params.page,
    size: params.size});
    const body = params.dataSearch ? params.dataSearch : {}
  return httpAdmin.post(`${END_POINT.GET_LIST_COURSE_ADMIN}?${url}`,body).then((res) => res);
}
function getImportListCourseAdmin(data) {
  return httpAdmin.post(`${END_POINT.IMPORT_LIST_COURSE_ADMIN}`,data,{
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res);
}
function getCoursedependenciesAdmin(params) {
  const url = convertQueryString(params);
  const body = params.dataSearch ? params.dataSearch : {}
return httpAdmin.post(`${END_POINT.LIST_COURSE_DEPENDENCIES_ADMIN}?${url}`,body).then((res) => res);
}
function getInvalidCoursedependenciesAdmin(params) {
  const url = convertQueryString({page:  params.page ?? 0,
    size: params.size ?? 50,
    temporary : params.temporary ?? 0,
  });
  const body = params.dataSearch ? params.dataSearch : {}
return httpAdmin.post(`${END_POINT.LIST_INVALID_COURSE_DEPENDENCIES_ADMIN}?${url}`,body).then((res) => res);
}
function get12GroupAdmin(params) {
  const url = convertQueryString({page: params.page ?? 0, size: params.size, school_group: params.school_group});
  const body = params.dataSearch ? params.dataSearch : {}
return httpAdmin.post(`${END_POINT.LIST_12_GROUP_ADMIN}?${url}`,body).then((res) => res);
}
function getInvalidCourseAdmin(params) {
  const url = convertQueryString({page: params.page ?? 0, size: params.size??50, temporary : params.temporary ?? 0,});
  const body = params.dataSearch ? params.dataSearch : {}
return httpAdmin.post(`${END_POINT.LIST_INVALID_COURSE_ADMIN}?${url}`,body).then((res) => res);
}
function getImportCoursedependenciesAdmin(data) {
  return httpAdmin.post(`${END_POINT.IMPORT_LIST_COURSE_DEPENDENCIES_ADMIN}`,data,{
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res);
}
function getImportSeihoku(data) {
  return httpAdmin.post(`${END_POINT.IMPORT_SEIHOKU_ADMIN}`,data,{
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res);
}
function getImportSchoolGroup(data) {
  return httpAdmin.post(`${END_POINT.IMPORT_SCHOOL_GROUP_ADMIN}`,data,{
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res);
}
function getListSchoolGroup(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_LIST_SCHOOL_GROUP_ADMIN}?${url}`).then((res) => res);
}
function loginAdmin(data) {
  return httpAdmin.post(`${END_POINT.LOGIN_ADMIN}`,data,{
  }).then((res) => res);
}
function getSeries() {
  return httpAdmin.get(`${END_POINT.GET_SERIES}`).then((res) => res);
}
function getSubject() {
  return httpAdmin.get(`${END_POINT.GET_SUBJECT}`).then((res) => res);
}
function getClassification() {
  return httpAdmin.get(`${END_POINT.GET_CLASSIFICATION}`).then((res) => res);
}
function getMethod() {
  return httpAdmin.get(`${END_POINT.GET_METHOD}`).then((res) => res);
}
function getContent() {
  return httpAdmin.get(`${END_POINT.GET_CONTENT}`).then((res) => res);
}
function getEdited() {
  return httpAdmin.get(`${END_POINT.GET_EDITED}`).then((res) => res);
}
function getExam() {
  return httpAdmin.get(`${END_POINT.GET_EXAM}`).then((res) => res);
}
function getUniversityId(params) {
  const url = convertQueryString(params);
  return httpAdmin.get(`${END_POINT.GET_UNIVERSITY_ID}?${url}`).then((res) => res);
}

function getVersion(params) {
  const url = convertQueryString(params);
  return httpAdmin.get(`${END_POINT.GET_VERSION}?${url}`).then((res) => res);
}
function getListSeihoku(params) {
  const url = convertQueryStringAdmin(params);
return httpAdmin.get(`${END_POINT.GET_LIST_SEIHOKU_ADMIN}?${url}`).then((res) => res);
}
function getListValidSchool(params) {
  const url = convertQueryStringAdmin(params);
return httpAdmin.get(`${END_POINT.GET_LIST_VALID_SCHOOL}?${url}`).then((res) => res);
}
function getListInValidSchool(params) {
  const url = convertQueryStringAdmin(params);
return httpAdmin.get(`${END_POINT.GET_LIST_INVALID_SCHOOL}?${url}`).then((res) => res);
}
function getListInValidSeihoku(params) {
  const url = convertQueryString({page: 0, size: 50});
return httpAdmin.get(`${END_POINT.GET_LIST_INVALID_SEIHOKU}?${url}`).then((res) => res);
}
function getDetailSeihoku(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_DETAIL_SEIHOKU_ADMIN}?${url}`).then((res) => res);
}
function getVersionSeihoku(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_VERSION_SEIHOKU_ADMIN}?${url}`).then((res) => res);
}
function getSchoolGroup(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_SCHOOL_GROUP}?${url}`).then((res) => res);
}
function getVersionImport(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_VERSION_IMPORT}?${url}`).then((res) => res);
}

function getListDataVersion(params) {
  const url = convertQueryStringAdmin(params);
return httpAdmin.get(`${END_POINT.GET_LIST_DATA_VERSION}?${url}`).then((res) => res);
}
function newVersion(params) {
return httpAdmin.post(`${END_POINT.NEW_VERSION}`,params).then((res) => res);
}
function confirmImport(data) {
  const url = convertQueryStringAdmin(data);
  return httpAdmin.post(`${END_POINT.CANCEL_IMPORT}?${url}`).then((res) => res);
}
function importStepVersion(data) {
  const url = convertQueryStringAdmin(data);
  return httpAdmin.post(`${END_POINT.IMPORT_STEP_VERSION}?${url}`).then((res) => res);
}
function deleteScheduled(data) {
  return httpAdmin.post(`${END_POINT.DELETE_SCHEDULED}/${data?.studentID}`,data).then((res) => res);

}
function getListSchedules(params) {
  const url = convertQueryString({page:  params.page,
    size: params.size});
    const body = params.dataSearch ? params.dataSearch : {}
  return httpAdmin.post(`${END_POINT.GET_LIST_SCHEDULES}?${url}`,body).then((res) => res);
}
function listStudentId() {
  return httpAdmin.get(`${END_POINT.GET_LIST_STUDENT_ID}`).then((res) => res);
}
function listStudentName() {
  return httpAdmin.get(`${END_POINT.GET_LIST_STUDENT_NAME}`).then((res) => res);
}
function listUniversityAdmin() {
  return httpAdmin.get(`${END_POINT.GET_LIST_UNIVERSITY_ADMIN}`).then((res) => res);
}
function listUniversityFullInfomation(params) {
  const url = convertQueryString(params);
return httpAdmin.get(`${END_POINT.GET_LIST_FULL_UNIVERSITY_INFOMATION}?${url}`).then((res) => res);
}
function getDetailCurriculum(params) {
return httpAdmin.get(`${END_POINT.GET_DETAIL_CURRICULUM}/${params.curriculumID }`).then((res) => res);
}
function getLinkDownload(params) {
return httpAdmin.get(`${END_POINT.GET_LINK_DOWNLOAD}`).then((res) => res);
}
function deleteManagerSchedules(data) {
  return httpAdmin.post(`${END_POINT.DELETE_MANAGER_SCHEDULED}`,data).then((res) => res);
}
function maintenanceAdmin(data) {
  return httpAdmin.post(`${END_POINT.MAINTENANCE_ADMIN}`,data).then((res) => res);
}
function getInfoMaintenanceAdmin() {
  return httpAdmin
    .get(END_POINT.GET_INFO_MAINTENANCE_ADMIN)
    .then((res) => res);
}
export {
  getListCourseAdmin,
  getImportListCourseAdmin,
  getImportCoursedependenciesAdmin,
  getImportSeihoku,
  getImportSchoolGroup,
  getListSchoolGroup,
  getCoursedependenciesAdmin,
  loginAdmin,
  getSeries,
  getSubject,
  getClassification,
  getMethod,
  getContent,
  getEdited,
  getExam,
  getVersion,
  getListSeihoku,
  getListValidSchool,
  getDetailSeihoku,
  getVersionSeihoku,
  get12GroupAdmin,
  getSchoolGroup,
  getInvalidCourseAdmin,
  getUniversityId,
  getListInValidSchool,
  getInvalidCoursedependenciesAdmin,
  getVersionImport,
  getListDataVersion,
  newVersion,
  confirmImport,
  importStepVersion,
  deleteScheduled,
  getListSchedules,
  listStudentId,
  listStudentName,
  listUniversityAdmin,
  listUniversityFullInfomation,
  getDetailCurriculum,
  getLinkDownload,
  deleteManagerSchedules,
  getListInValidSeihoku,
  maintenanceAdmin,
  getInfoMaintenanceAdmin
};
