import { http } from "Config";
import END_POINT from "Constants/endpoint";
import { convertQueryString } from "Utils/string";

function getStudentInformation(data) {
  return http.get(END_POINT.STUDENT_INFO, data).then((res) => res);
}

function getSubjectContent(data) {
  return http.get(END_POINT.SUBJECT_CONTENT, data).then((res) => res);
}

function getMaxScore(data) {
  const url = convertQueryString(data);
  return http.get(END_POINT.MAX_SCORE, url, {}, true).then((res) => res);
}

function getScheduleCourse(data) {
  return http.post(END_POINT.GET_SCHEDULE_COURSE, data).then((res) => res);
}

function exportScheduleCourse(data) {
  return http.post(END_POINT.EXPORT_SCHEDULE_COURSE, data).then((res) => res);
}

function getCompulsorySubjects(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.COMPULSORY_SUBJECTS, url, {}, true)
    .then((res) => res);
}

function getCompulsorySubjectsV2(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.COMPULSORY_SUBJECTS_V2, url, {}, true)
    .then((res) => res);
}

function getInformationPrint(data) {
  return http.get(END_POINT.GET_INFORMATION_PRINT, data).then((res) => res);
}

function getAllCourseName(data) {
  const url = convertQueryString(data);
  return http.get(END_POINT.ALL_COURSE_NAME, url, {}, true).then((res) => res);
}
function getAllCourseFilter(data) {
  const url = convertQueryString(data);
  return http.get(END_POINT.GET_ALL_COURSE_FILTER, url, {}, true).then((res) => res);
}
function addCourseFilter(data) {
  return http.post(END_POINT.ADD_COURSE_FILTER,data ).then((res) => res);
}

function searchCourseName(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.SEARCH_COURSE_NAME, url, {}, true)
    .then((res) => res);
}

function getCourseByName(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.GET_COURSE_BY_NAME, url, {}, true)
    .then((res) => res);
}

function addNewClassSchedule(data) {
  return http
    .post(END_POINT.ADD_NEW_CLASS_SCHEDULE, data)
    .then((res) => res);
}

function getCourseTypeName(data) {
  return http
    .get(END_POINT.GET_COURSE_TYPE_NAME, data)
    .then((res) => res);
}

function getCourseSubjects(data) {
  return http
    .get(END_POINT.GET_COURSE_SUBJECTS, data)
    .then((res) => res);
}

function getCourseTypeCourse(data) {
  return http
    .get(END_POINT.GET_COURSE_TYPE_COURSE, data)
    .then((res) => res);
}

function getCourseTeacherName(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.GET_COURSE_TEACHER_NAME, url, {}, true)
    .then((res) => res);
}
function getCourseContents(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.GET_COURSE_CONTENTS, url, {}, true)
    .then((res) => res);
}

function getInforStudentSaved(data) {
  return http
    .get(`${END_POINT.GET_INFOR_STUDENT_SAVED}/${data}`)
    .then((res) => res);
}

function getListUniversity(data) {
  return http
    .get(END_POINT.GET_LIST_UNIVERSITY, data)
    .then((res) => res);
}

function getFullUniversityInformation(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.GET_FULL_UNIVERSITY_INFORMATION, url, {}, true)
    .then((res) => res);
}

function getAspirationalId(data) {
  const url = convertQueryString(data);
  return http
    .get(END_POINT.GET_ASPIRATIONAL_ID, url, {}, true)
    .then((res) => res);
}

function updateCalender(data) {
  return http
    .post(END_POINT.UPDATE_CALENDER, data)
    .then((res) => res);
}

function getListCurriculum(data) { 
  return http
    .get(`${END_POINT.GET_LIST_CURRICULUM}?studentID=${data.studentID}&page=${data.page}&size=${data.size}`)
    .then((res) => res);
}

function getCurriculumDetail(data) {
  const dataCurriculum = {
    curriculumID: data.curriculumID
  }
  const url = convertQueryString(dataCurriculum);
  return http
    .get(`${END_POINT.GET_CURRICULUM_DETAIL}/${data.studentID}`, url, {}, true)
    .then((res) => res);
}

function activeCurriculumID(data) {
  
  return http
    .post(`${END_POINT.ACTIVE_AI_CURRICULUM}?studentID=${data.studentID}&curriculumID=${data.curriculumID}`)
    .then((res) => res);
}
function deleteCurriculumID(data) {
  return http
    .post(`${END_POINT.DELETE_AI_CURRICULUM}`,data)
    .then((res) => res);
}
function addAiCurriculum(data) {
  return http
    .post(END_POINT.ADD_AI_CURRICULUM, data)
    .then((res) => res);
}

function getInfoMaintenance() {
  return http
    .get(END_POINT.GET_INFO_MAINTENANCE)
    .then((res) => res);
}

export {
  getStudentInformation,
  getSubjectContent,
  getMaxScore,
  getScheduleCourse,
  exportScheduleCourse,
  getCompulsorySubjects,
  getCompulsorySubjectsV2,
  getAllCourseName,
  getAllCourseFilter,
  addCourseFilter,
  searchCourseName,
	getCourseByName,
	addNewClassSchedule,
  getCourseTypeName,
  getCourseSubjects,
  getCourseTypeCourse,
  getInforStudentSaved,
  getListUniversity,
  getFullUniversityInformation,
  getAspirationalId,
  getInformationPrint,
  updateCalender,
  getListCurriculum,
  getCurriculumDetail,
  addAiCurriculum,
  activeCurriculumID,
  deleteCurriculumID,
  getInfoMaintenance,
  getCourseTeacherName,
  getCourseContents
};
